import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { Directus } from "@directus/sdk";

const PROJECTURL = "https://intos.targaserver3.nl";
const DIRECTUS_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9";
const DIRECTUS_URL_BASE = "https://intos-directus.targaserver3.nl";
const DIRECTUS_URL_ASSETS = "https://intos-directus.targaserver3.nl/assets/";

const directus = new Directus(DIRECTUS_URL_BASE);

export default new Vuex.Store({
	state: {
		DIRECTUS_TOKEN: DIRECTUS_TOKEN,
		DIRECTUS_URL_ASSETS: DIRECTUS_URL_ASSETS,
		teams: null,
		employees: null,
	},
	mutations: {
		setTeams(state, payload) {
			state["teams"] = payload;
		},
		setEmployees(state, payload) {
			state["employees"] = payload;
		},
	},
	actions: {
		getTeams: async function ({ dispatch, commit }) {
			await directus.auth.static(DIRECTUS_TOKEN);
			return await directus
				.items("teams")
				.readByQuery({
					fields: ["*", "images.directus_files_id.id"],
					filter: {
						_and: [
							{
								status: {
									_eq: "published",
								},
							},
						],
					},
					limit: -1,
					sort: ["sort"],
				})
				.then((response) => {
					if (response && response.data) {
						commit("setTeams", response.data);
					}
				});
		},
		getEmployees: async function ({ dispatch, commit }) {
			await directus.auth.static("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9");
			return await directus
				.items("employees")
				.readByQuery({
					fields: ["*", "team.*"],
					filter: {
						_and: [
							{
								status: {
									_eq: "published",
								},
							},
						],
					},
					limit: -1,
					sort: "sort",
				})
				.then((response) => {
					if (response && response.data) {
						commit("setEmployees", response.data);
					}
				});
		},
	},
	modules: {},
});
