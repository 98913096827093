<template>
	<div>
		<div class="video-background">
			<div class="video-foreground">
				<iframe src="https://www.youtube.com/embed/8Yv9vBDdwTE?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1" frameborder="0" allowfullscreen></iframe>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "SchermVideo",
	components: {},
	data() {
		return {
			scale: 1,
			canvas: {
				width: 1920,
				height: 1080,
			},
			hexagon: {
				width: 130,
				height: 130 * 1.159375,
				trans_x: 128,
				trans_y: 128 * 1.159375 * 0.75,
			},
		};
	},
	computed: {
		screenIndex() {
			return this.$route.query.screenIndex;
		},
	},
	methods: {
		scaleOuterContainer() {
			let container = this.$refs.outer_container;
			const minScale = 0.05;
			const maxScale = 4;
			let scale = Math.min(window.innerWidth / container.offsetWidth, window.innerHeight / container.offsetHeight);
			scale = Math.min(maxScale, Math.max(minScale, scale));
			console.log(scale);
			this.scale = scale;
		},
	},
	mounted() {
		let self = this;
		self.scaleOuterContainer();
	},
	created() {
		window.addEventListener("resize", this.scaleOuterContainer);
	},
};
</script>

<style>
* {
	box-sizing: border-box;
}
.video-background {
	background: #000;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -99;
}
.video-foreground,
.video-background iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}
#vidtop-content {
	top: 0;
	color: #fff;
}
.vid-info {
	position: absolute;
	top: 0;
	right: 0;
	width: 33%;
	background: rgba(0, 0, 0, 0.3);
	color: #fff;
	padding: 1rem;
	font-family: Avenir, Helvetica, sans-serif;
}
.vid-info h1 {
	font-size: 2rem;
	font-weight: 700;
	margin-top: 0;
	line-height: 1.2;
}
.vid-info a {
	display: block;
	color: #fff;
	text-decoration: none;
	background: rgba(0, 0, 0, 0.5);
	transition: 0.6s background;
	border-bottom: none;
	margin: 1rem auto;
	text-align: center;
}
@media (min-aspect-ratio: 16/9) {
	.video-foreground {
		height: 300%;
		top: -100%;
	}
}
@media (max-aspect-ratio: 16/9) {
	.video-foreground {
		width: 300%;
		left: -100%;
	}
}
@media all and (max-width: 600px) {
	.vid-info {
		width: 50%;
		padding: 0.5rem;
	}
	.vid-info h1 {
		margin-bottom: 0.2rem;
	}
}
@media all and (max-width: 500px) {
	.vid-info .acronym {
		display: none;
	}
}
</style>
