<template>
	<div>
		<div class="outer_container" ref="outer_container" :style="{ height: '825px', background: '#333', transform: 'scale(' + scale + ')' }">
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=1"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=2"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=3"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=4"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=5"></iframe>
			<iframe class="iframe" src="/scherm-video"></iframe>
			<iframe class="iframe" src="/scherm-logo"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=8"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=9"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=10"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=11"></iframe>
			<iframe class="iframe" src="/scherm-smoelenboek?screenIndex=12"></iframe>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "SchermOverzicht",
	components: {},
	data() {
		return {
			scale: 1,
			canvas: {
				width: 1920,
				height: 1080,
			},
			hexagon: {
				width: 130,
				height: 130 * 1.159375,
				trans_x: 128,
				trans_y: 128 * 1.159375 * 0.75,
			},
		};
	},
	computed: {
		screenIndex() {
			return this.$route.query.screenIndex;
		},
	},
	methods: {
		scaleOuterContainer() {
			let container = this.$refs.outer_container;
			const minScale = 0.05;
			const maxScale = 4;
			let scale = Math.min(window.innerWidth / container.offsetWidth, window.innerHeight / container.offsetHeight);
			scale = Math.min(maxScale, Math.max(minScale, scale));
			console.log(scale);
			this.scale = scale;
		},
	},
	mounted() {
		let self = this;
		self.scaleOuterContainer();
	},
	created() {
		window.addEventListener("resize", this.scaleOuterContainer);
	},
};
</script>

<style>
.iframe {
	width: 470px;
	height: 265px;
	margin: 5px;
	background: #333333;
	padding: 0px;
	float: left;
	border: 0px;
}
</style>
