import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import dayjs from "vue-dayjs";

Vue.use(dayjs);

new Vue({
	router,
	store,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
