import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SchermSmoelenboek from "../views/SchermSmoelenboek/index.vue";
import SchermLogo from "../views/SchermLogo/index.vue";
import SchermVideo from "../views/SchermVideo/index.vue";
import SchermOverzicht from "../views/SchermOverzicht/index.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/scherm-smoelenboek",
		name: "SchermSmoelenboek",
		component: SchermSmoelenboek,
	},

	{
		path: "/scherm-logo",
		name: "SchermLogo",
		component: SchermLogo,
	},
	{
		path: "/scherm-video",
		name: "SchermVideo",
		component: SchermVideo,
	},
	{
		path: "/scherm-overzicht",
		name: "SchermOverzicht",
		component: SchermOverzicht,
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/About.vue");
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
