<template>
	<div>
		<div
			class="outer_container"
			ref="outer_container"
			:style="{ transform: 'scale(' + scale + ')' }"
		>
			<div
				class="hexagon_container"
				v-for="i in parseInt(layout.hexagon.quantity + layout.hexagon.quantity_x)"
				:key="i"
				:style="{ left: hexagonPosition(i).x + 'px', top: hexagonPosition(i).y + 'px' }"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #3a8be3"
					:style="{ width: layout.hexagon.width + 'px', height: layout.hexagon.height + 'px' }"
					viewBox="0 0 256 296.8"
					fill="currentColor"
				>
					<path d="M128,295.7L0.9,222.1V74.7L128,1.1l127.1,73.7v147.3L128,295.7z M2.8,221L128,293.6L253.2,221V75.8L128,3.2
	L2.8,75.8V221z" />
				</svg>
			</div>

			<div class="hexagon_background hexagon_background_cl">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 22.6 26.2"
					fill="currentColor"
				>
					<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
				</svg>
			</div>

			<div class="wijzijnintos">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 464.66 428.54"
					fill="currentColor"
				>
					<path d="M28.7,0,46.9,68.18,69.3,0H91.14l22.4,68.18L131.74,0h28.7l-31.5,105.56h-28L80.22,44.38,59.5,105.56h-28L0,0Z" />
					<path d="M198.94,0V105.56H171.5V0Zm70.14,0V79.66a68.4,68.4,0,0,1-.49,8.54A27.39,27.39,0,0,1,267,94.92,31.15,31.15,0,0,1,259,106q-9.8,9-24.36,9-16.94,0-27.58-15.54L225,81.06a14.75,14.75,0,0,0,3.08,7.56,7.42,7.42,0,0,0,5.88,2.8q7.69,0,7.7-12.18V0Z" />
					<path d="M48.16,242.34H92.4v23.24H2.1l50-82.32H10.5V160H97.86Z" />
					<path d="M138.46,160V265.58H111V160Zm70.14,0v79.66a70.37,70.37,0,0,1-.49,8.54,27.39,27.39,0,0,1-1.61,6.72,31.15,31.15,0,0,1-8,11.06q-9.79,9-24.36,9-16.93,0-27.58-15.54l17.92-18.34a14.67,14.67,0,0,0,3.08,7.56,7.41,7.41,0,0,0,5.88,2.8q7.69,0,7.7-12.18V160Z" />
					<path d="M230.72,265.58V160h27.44l50.68,64.54V160h27.3V265.58h-27.3L258.16,201v64.54Z" />
					<path d="M38.5,320V425.6H11.06V320Z" />
					<path d="M60.62,425.6V320H88.06l50.68,64.54V320H166V425.6h-27.3L88.06,361.06V425.6Z" />
					<path d="M228.2,343.28V425.6H200.76V343.28H178.22V320h72.52v23.24Z" />
					<path d="M257.18,372.82a55,55,0,0,1,4.34-21.91,54.48,54.48,0,0,1,12-17.71A55,55,0,0,1,292,321.37a67.1,67.1,0,0,1,47.11,0,55.75,55.75,0,0,1,18.55,11.83,54.21,54.21,0,0,1,12.11,17.71,57.48,57.48,0,0,1,0,43.82,54.21,54.21,0,0,1-12.11,17.71,55.75,55.75,0,0,1-18.55,11.83,67.1,67.1,0,0,1-47.11,0,55,55,0,0,1-18.41-11.83,54.48,54.48,0,0,1-12-17.71A55,55,0,0,1,257.18,372.82Zm28.7,0a28.09,28.09,0,0,0,2.38,11.62,29.06,29.06,0,0,0,15.89,15.33,30.53,30.53,0,0,0,32.34-6.09,28.87,28.87,0,0,0,6.51-9.24,29.56,29.56,0,0,0,0-23.24,28.87,28.87,0,0,0-6.51-9.24,30.79,30.79,0,0,0-32.34-6.09,29.06,29.06,0,0,0-15.89,15.33A28.09,28.09,0,0,0,285.88,372.82Z" />
					<path d="M449.4,347.06a34,34,0,0,0-9-5.39,23.74,23.74,0,0,0-8.68-1.75,14.08,14.08,0,0,0-8.68,2.52,7.85,7.85,0,0,0-3.36,6.58,6.53,6.53,0,0,0,1.68,4.62,14.76,14.76,0,0,0,4.41,3.15A37,37,0,0,0,432,359.1c2.28.65,4.55,1.36,6.79,2.1q13.44,4.49,19.67,12t6.23,19.53a38,38,0,0,1-2.73,14.7,30.67,30.67,0,0,1-8,11.27A36.88,36.88,0,0,1,441.07,426a53.56,53.56,0,0,1-17.29,2.59,63.59,63.59,0,0,1-37.1-11.9l11.76-22.12a47.39,47.39,0,0,0,12.18,8.12,29.15,29.15,0,0,0,11.9,2.66q6.72,0,10-3.08A9.54,9.54,0,0,0,435,391.09a9,9,0,0,0-2.8-3.22,22.51,22.51,0,0,0-5.11-2.73c-2.1-.84-4.65-1.77-7.63-2.8q-5.32-1.68-10.43-3.71a32.69,32.69,0,0,1-9.1-5.39,25,25,0,0,1-6.44-8.47Q391,359.66,391,351.82a37.35,37.35,0,0,1,2.59-14.21,31.38,31.38,0,0,1,7.28-10.92,32.41,32.41,0,0,1,11.48-7.07,43.37,43.37,0,0,1,15.19-2.52,66.41,66.41,0,0,1,16.38,2.17,72.52,72.52,0,0,1,16.38,6.37Z" />
				</svg>
			</div>

			<div class="logo_groot">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 748.5 262.1"
					fill="currentColor"
				>
					<g>
						<rect
							x="259"
							y="65.3"
							class="st0"
							width="19.3"
							height="128.1"
						/>
						<polygon
							class="st0"
							points="435,83.5 464.4,83.5 464.4,193.4 483.8,193.4 483.8,83.5 513.1,83.5 513.1,65.3 435,65.3 	"
						/>
						<path
							class="st0"
							d="M634.2,82.5c-13.1-13.1-28.7-19.6-47-19.6c-18.5,0-34.3,6.5-47.5,19.4c-13.2,12.9-19.8,28.4-19.8,46.5
		c0,20.5,7.2,37.1,21.7,49.8c13.2,11.4,28,17.2,44.6,17.2c18.7,0,34.7-6.4,47.9-19.3c13.2-12.8,19.7-28.5,19.7-46.9
		C653.8,111.2,647.3,95.5,634.2,82.5z M620.5,163.6c-9.2,9.3-20.6,13.9-34,13.9c-12.4,0-23.3-4.6-32.8-13.7
		c-9.5-9.1-14.3-20.8-14.3-34.9c0-13.5,4.6-24.8,13.8-34C562.4,85.7,573.6,81,587,81c13.4,0,24.6,4.6,33.7,13.9
		c9.1,9.2,13.7,20.7,13.7,34.3C634.3,142.8,629.7,154.2,620.5,163.6z"
						/>
						<path
							class="st0"
							d="M708,177.5c-7.6,0-13.4-2.8-17.4-8.3c-2.2-2.9-3.8-8.1-4.6-15.6l-19.6,4.3c1.8,11.9,6.2,21.2,13.4,27.8
		c7.2,6.6,16.4,9.9,27.5,9.9c11.7,0,21.5-3.9,29.5-11.6c7.9-7.7,11.8-17.4,11.8-29.2c0-8.8-2.4-16.2-7.2-22.3
		c-4.8-6.1-12.4-11.2-22.8-15.4l-12.8-5.2c-9.3-3.8-13.9-8.9-13.9-15.1c0-4.5,1.8-8.3,5.3-11.3c3.5-3,8-4.6,13.4-4.6
		c4.4,0,7.9,0.9,10.7,2.7c2.7,1.5,5.4,4.9,8.4,10l15.7-9.3c-8.3-14.3-19.8-21.5-34.6-21.5c-11,0-20.1,3.3-27.5,9.8
		c-7.4,6.5-11,14.5-11,24.1c0,14.3,8.8,25.1,26.3,32.5l12.4,5.1c3.2,1.4,6,2.8,8.3,4.4c2.3,1.5,4.2,3.2,5.7,4.9
		c1.5,1.7,2.5,3.6,3.2,5.7c0.7,2.1,1,4.4,1,6.8c0,6.2-2,11.3-6,15.3C719,175.5,714,177.5,708,177.5z"
						/>
						<polygon
							class="st0"
							points="327,109.4 412.2,193.3 412.2,65.2 392.8,65.2 392.9,149.3 307.7,65.2 307.7,193.3 327,193.3 	"
						/>
						<rect
							x="258.9"
							y="225.9"
							class="st0"
							width="3.2"
							height="28.5"
						/>
						<polygon
							class="st0"
							points="297,248 274.8,224.7 274.8,254.4 278,254.4 278,232.4 300.2,255.7 300.2,225.9 297,225.9 	"
						/>
						<polygon
							class="st0"
							points="310.4,228.8 317.3,228.8 317.3,254.4 320.5,254.4 320.5,228.8 327.5,228.8 327.5,225.9 310.4,225.9 	
		"
						/>
						<polygon
							class="st0"
							points="337.5,254.4 352.3,254.4 352.3,251.5 340.7,251.5 340.7,240.3 352,240.3 352,237.3 340.7,237.3 
		340.7,228.8 352.3,228.8 352.3,225.9 337.5,225.9 	"
						/>
						<path
							class="st0"
							d="M379.1,233.8c0-6.4-5-7.9-10.4-7.9h-4.1v28.5h3.2v-12.5h0.8l8.6,12.5h3.9l-9.1-12.8
		C376.3,241.2,379.1,238.1,379.1,233.8z M367.7,239.1v-10.3h0.9c3.8,0,7.3,0.5,7.3,5.1c0,4.4-3.6,5.1-7.2,5.1H367.7z"
						/>
						<rect
							x="391.9"
							y="225.9"
							class="st0"
							width="3.2"
							height="28.5"
						/>
						<path
							class="st0"
							d="M421.1,225.4c-8.1,0-14.9,6.6-14.9,14.8c0,8.2,6.7,14.7,14.9,14.7c8.1,0,14.9-6.5,14.9-14.7
		C435.9,232,429.2,225.4,421.1,225.4z M421.1,251.9c-6.5,0-11.7-5.4-11.7-11.8c0-6.5,5.1-11.8,11.7-11.8c6.5,0,11.7,5.3,11.7,11.8
		C432.8,246.6,427.5,251.9,421.1,251.9z"
						/>
						<path
							class="st0"
							d="M461.6,233.8c0-6.4-5-7.9-10.4-7.9h-4.1v28.5h3.2v-12.5h0.8l8.6,12.5h3.9l-9.1-12.8
		C458.9,241.2,461.6,238.1,461.6,233.8z M450.2,239.1v-10.3h0.9c3.8,0,7.3,0.5,7.3,5.1c0,4.4-3.6,5.1-7.2,5.1H450.2z"
						/>
						<path
							class="st0"
							d="M502.5,238.1l-1.9-0.8c-1.9-0.9-4.5-2-4.5-4.5c0-2.6,2.2-4.4,4.7-4.4c2.4,0,3.8,1.1,4.9,3.1l2.5-1.6
		c-1.5-2.8-4.2-4.4-7.4-4.4c-4.2,0-8,2.8-8,7.3c0,4.1,2.9,5.8,6.2,7.3l1.7,0.8c2.6,1.2,5.3,2.3,5.3,5.7c0,3.2-2.8,5.6-5.9,5.6
		s-5.3-2.4-5.7-5.3l-3.1,0.9c0.9,4.4,4.3,7.4,8.9,7.4c5,0,9-3.8,9-8.8C509.3,241.5,506.2,239.7,502.5,238.1z"
						/>
						<path
							class="st0"
							d="M534.2,225.4c-8.1,0-14.9,6.6-14.9,14.8c0,8.2,6.7,14.7,14.9,14.7c8.1,0,14.9-6.5,14.9-14.7
		C549.1,232,542.3,225.4,534.2,225.4z M534.2,251.9c-6.5,0-11.7-5.4-11.7-11.8c0-6.5,5.1-11.8,11.7-11.8c6.5,0,11.7,5.3,11.7,11.8
		C545.9,246.6,540.7,251.9,534.2,251.9z"
						/>
						<polygon
							class="st0"
							points="563.3,225.9 560.2,225.9 560.2,254.4 571.2,254.4 571.2,251.5 563.3,251.5 	"
						/>
						<path
							class="st0"
							d="M598.2,242.9c0,2.2,0.1,4.7-1.4,6.5c-1.3,1.6-3.6,2.5-5.6,2.5c-2.1,0-4.5-1-5.8-2.7c-1.3-1.8-1.2-4.2-1.2-6.4
		v-17h-3.2v17.9c0,3.3,0.3,5.9,2.8,8.2c1.9,1.9,4.7,2.9,7.4,2.9c2.9,0,5.7-1.1,7.7-3.3c2.3-2.3,2.5-4.8,2.5-7.9v-17.9h-3.2V242.9z"
						/>
						<polygon
							class="st0"
							points="611.3,228.8 618.2,228.8 618.2,254.4 621.4,254.4 621.4,228.8 628.3,228.8 628.3,225.9 611.3,225.9 	
		"
						/>
						<rect
							x="638.5"
							y="225.9"
							class="st0"
							width="3.2"
							height="28.5"
						/>
						<path
							class="st0"
							d="M667.6,225.4c-8.1,0-14.9,6.6-14.9,14.8c0,8.2,6.7,14.7,14.9,14.7c8.1,0,14.9-6.5,14.9-14.7
		C682.5,232,675.8,225.4,667.6,225.4z M667.6,251.9c-6.5,0-11.7-5.4-11.7-11.8c0-6.5,5.1-11.8,11.7-11.8c6.5,0,11.7,5.3,11.7,11.8
		C679.3,246.6,674.1,251.9,667.6,251.9z"
						/>
						<polygon
							class="st0"
							points="716.1,248 693.8,224.7 693.8,254.4 697,254.4 697,232.4 719.2,255.7 719.2,225.9 716.1,225.9 	"
						/>
						<path
							class="st0"
							d="M741.6,238.1l-1.9-0.8c-1.9-0.9-4.5-2-4.5-4.5c0-2.6,2.2-4.4,4.7-4.4c2.4,0,3.8,1.1,4.9,3.1l2.5-1.6
		c-1.5-2.8-4.2-4.4-7.4-4.4c-4.2,0-8,2.8-8,7.3c0,4.1,2.9,5.8,6.2,7.3l1.7,0.8c2.6,1.2,5.3,2.3,5.3,5.7c0,3.2-2.8,5.6-5.9,5.6
		s-5.3-2.4-5.7-5.3l-3.1,0.9c0.9,4.4,4.3,7.4,8.9,7.4c5,0,9-3.8,9-8.8C748.4,241.5,745.3,239.7,741.6,238.1z"
						/>
					</g>
					<path
						class="st0"
						d="M114.9,262.1V133.7l111.2,64.2L114.9,262.1z M118.8,140.4v115l99.6-57.5L118.8,140.4z"
					/>
					<path
						class="st0"
						d="M111.2,262.1L0,197.9l111.2-64.2V262.1z M7.7,197.9l99.6,57.5v-115L7.7,197.9z"
					/>
					<path
						class="st0"
						d="M0,193.7V65.2l111.2,64.2L0,193.7z M3.9,71.9V187l99.6-57.5L3.9,71.9z"
					/>
					<path
						class="st0"
						d="M226.1,193.7l-111.2-64.2l111.2-64.2V193.7z M122.6,129.4l99.6,57.5V71.9L122.6,129.4z"
					/>
					<path
						class="st0"
						d="M113.1,0L3.8,63.1l109.2,63l109.2-63L113.1,0z M104.8,15.1c1.5,1.1,5.9,2.2,4,5.3c-2.5,1.6-2.9,5-6.1,5.6
	c-0.8,1.5-2.5,2.1-3.5,3.4c-2-0.2-4.3,2.3-5.6-0.1c-1.8,0.4-0.8-1.6-1.6-2.2C94.7,21.9,99.6,17.4,104.8,15.1z M170.7,69.9
	c-2,2.6-5.5,3-7.8,5.1c-5.6,3.2-10.9,7.3-17,9.5c-1.9,0.3-3.6,1.7-5.1,0.1c-2.2,0.1-3.7-1.5-4.9-3.2c-1.7-3.7-1.2-8.2,1.2-11.5
	c-0.1-0.3-0.1-0.7-0.5-0.8c-4.5,3.3-8.4,7.5-13.5,10c-0.4,1.6-1.8-0.5-4,1.6c-1.8,0.7-3.2-0.2-4.7-1c-3-2.4-2.7-6.7-1.7-9.9
	c0.5-1.2,1.3-2,1.9-3.5c-0.2-0.8,0.5-1.4,0.2-2.1c-1.3,0.8-2.2,2.9-3.8,3.6c-6.9,5.1-11.4,14.5-21,14.9c-1.4-0.2-2.3-1.5-2.2-2.8
	c-0.3-1.6,0.6-2.9,1-4.3c1.3-1.6,1.6-3.5,3.2-4.9c1.7-3.8,5.6-7.9,7.3-11.7c3.8-2.8,5.1-8,8.9-10.8c0.1-1.2,1.2-1,1-2.3
	c-1.3-0.4-1.5,1.4-2.7,1.4c-8.4,4.8-15.8,10.9-23.3,17.1c-5.6,2.9-10.1,7.5-15.4,10.9c-3.8,3.4-8,6.3-11.5,10.1
	c-1.3,0.6-2.5-0.3-3.7-0.8c-0.3-0.6-0.7-1.2-0.7-2.1c1-2.3,2.7-5.2,3.4-7.7c7.7-11,16.3-21.4,24.9-31.4l-0.5-0.3
	c-15.8,5.3-30.1,12.7-41.5,24.5c0.3,1-0.6,2-1.4,2.7c-1.1,0.5-1.7-0.5-2.6-1.2c-1.5-3.5,2.7-4.8,4.3-6.9
	c11.3-11.6,26.7-17.1,40.8-23.6c3.1-0.8,6.9-1.9,9.9-1.4c1.1,0.7,1.8,1.8,2.1,3c-1.4,3.9-5.1,4.8-6.7,8.3l-0.7,0
	C78.1,54,72.8,60.3,67.8,66.9c-0.8,0.4,0,1.2-0.4,1.7c13-8.7,25.2-18.7,39.5-25.7c2.4-1.5,4.9-3.1,7.8-3.3c1.2,0.4,2.1,1.4,2.5,2.6
	c-2.3,10.4-11.3,16.7-16.7,25.4l-3.9,5.2l0.3,1c1.8,0.3,2.1-2,3.5-2.6c6.2-4.9,11.5-10.1,17.2-15.7c0.4-0.1,0.9,0,1-0.4
	c2.9-2.4,5.9-5.1,9.1-7.4c1.8-0.6,3.8-0.2,4.4,1.7l0.1,1.5c-4.2,7.5-11.5,12.9-14.3,20.2c-0.2,1.4,0.2,2.8,1.6,3.1
	c0.5-0.6,1.1-0.5,1.8-0.3c2.5-0.5,3.6-2.9,6.2-3.4c1.8-2,4.4-2.9,6.4-4.9c6-4.8,12.1-9.7,18.2-14.6c1.6-0.6,3.4,0.1,4.1,1.8l0.3,1.5
	c-5.4,8.1-13.4,13.5-16.3,23c0.5,0.7,0.2,2.2,1.5,2.1c2.2-0.3,5-0.3,6.4-2.1c0.2,0.5,1.2,0.2,1.6-0.1c6.1-3.8,13.1-7.2,19.2-11
	c5.8-3.8,11.7-8.1,18.5-9.9c0.6-0.4,2-0.9,2.8-0.9c1.3-0.2,3.4-3.3,3.9-0.8c-2.9,2.2-6,4.2-8.8,6.7
	C180.1,63.5,175.5,67.2,170.7,69.9z"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "SchermLogo",
	components: {},
	data() {
		return {
			scale: 1,
			canvas: {
				width: 1920,
				height: 1080,
			},
			hexagon: {
				width: 130,
				height: 130 * 1.159375,
				trans_x: 128,
				trans_y: 128 * 1.159375 * 0.75,
			},
		};
	},
	computed: {
		screenIndex() {
			return this.$route.query.screenIndex;
		},
		layout() {
			let self = this;
			return {
				canvas: self.canvas,
				hexagon: {
					quantity_x: Math.ceil(
						self.canvas.width / self.hexagon.trans_x
					),
					quantity_y: Math.ceil(
						self.canvas.height / self.hexagon.trans_y
					),
					quantity:
						Math.ceil(self.canvas.width / self.hexagon.trans_x) *
						Math.ceil(self.canvas.height / self.hexagon.trans_y),
					totalwidth:
						Math.ceil(self.canvas.width / self.hexagon.trans_x) *
						self.hexagon.trans_x,
					totalheight:
						Math.ceil(self.canvas.height / self.hexagon.trans_y) *
						self.hexagon.trans_y,
					start_x: -1,
					start_y: -54,
					...self.hexagon,
				},
			};
		},
	},
	methods: {
		hexagonPosition(nmbr) {
			let self = this;
			let rowindex =
				(Math.floor((nmbr - 1) / self.layout.hexagon.quantity_x) *
					self.layout.hexagon.quantity_x) /
				self.layout.hexagon.quantity_x;
			let colindex = nmbr - 1 - rowindex * self.layout.hexagon.quantity_x;
			let rowOdd = rowindex % 2 == 1;
			let pos_x =
				self.layout.hexagon.start_x +
				colindex * self.layout.hexagon.trans_x -
				(rowOdd ? self.layout.hexagon.trans_x / 2 : 0);
			let pos_y =
				self.layout.hexagon.start_y +
				rowindex * self.layout.hexagon.trans_y;
			return { x: pos_x, y: pos_y };
		},
		scaleOuterContainer() {
			let container = this.$refs.outer_container;
			const minScale = 0.05;
			const maxScale = 4;
			let scale = Math.min(
				(window.innerWidth + 2) / container.offsetWidth,
				(window.innerHeight + 2) / container.offsetHeight
			);
			scale = Math.min(maxScale, Math.max(minScale, scale));
			console.log(scale);
			this.scale = scale;
		},
	},
	mounted() {
		let self = this;
		self.scaleOuterContainer();
		/*
		self.hexagonIndexes.forEach((i, index) => {
			self.employees.push({
				id: index + 1,
				hexagonIndex: i,
				name: "Employee " + index,
				photoUrl: "https://i.pravatar.cc/256",
			});
		});
		*/
	},
	created() {
		window.addEventListener("resize", this.scaleOuterContainer);
	},
};
</script>

<style>
.wijzijnintos {
	position: absolute;
	color: #00a0e9;
	z-index: 2;
	top: 240px;
	left: 80px;
}
.wijzijnintos svg {
	width: 560px;
}
.logo_groot {
	position: absolute;
	color: #ffffff;
	z-index: 2;
	top: 340px;
	left: 930px;
}
.logo_groot svg {
	width: 880px;
}
</style>
