<template>
	<div v-if="employees">
		<div
			class="outer_container"
			ref="outer_container"
			:style="{ transform: 'scale(' + scale + ')' }"
		>
			<div style="z-index:1000;position:absolute;right:10px;bottom:10px;font-size:10px;">{{$dayjs().format('DD-MM-YYYY HH:mm:ss')}}</div>
			<div
				class="hexagon_container"
				v-for="i in parseInt(layout.hexagon.quantity + layout.hexagon.quantity_x)"
				:key="i"
				:style="{ left: hexagonPosition(i).x + 'px', top: hexagonPosition(i).y + 'px' }"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #3a8be3"
					:style="{ width: layout.hexagon.width + 'px', height: layout.hexagon.height + 'px' }"
					viewBox="0 0 256 296.8"
					fill="currentColor"
				>
					<path d="M128,295.7L0.9,222.1V74.7L128,1.1l127.1,73.7v147.3L128,295.7z M2.8,221L128,293.6L253.2,221V75.8L128,3.2
	L2.8,75.8V221z" />
				</svg>
			</div>
			<div
				class="hexagon_container birthday"
				:class="employee.pos"
				v-for="(employee) in employees"
				:key="employee.id"
				:style="{ width: layout.hexagon.width + 'px', height: layout.hexagon.height + 'px', left: hexagonPosition(employee.hexagonIndex).x + 'px', top: hexagonPosition(employee.hexagonIndex).y + 'px' }"
			>
				<div class="absolute">
					<div
						class="hexagon_photo"
						:style="{ width: layout.hexagon.width + 'px', height: layout.hexagon.height + 'px', backgroundImage: 'url(' + employee.photoUrl + ')' }"
					></div>
				</div>
				<div class="absolute">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						style="color: #ffffff"
						:style="{ width: layout.hexagon.width, height: layout.hexagon.height }"
						viewBox="0 0 256 296.8"
						fill="currentColor"
					>
						<path d="M128,296.8L0,222.6V74.2L128,0l128,74.2v148.4L128,296.8z M3.7,220.5L128,292.6l124.3-72.1V76.3L128,4.2
	L3.7,76.3V220.5z" />
					</svg>
				</div>

				<div
					class="birthday"
					v-if="showBirthDay(employee.birthday) && employee.show_birthday"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						:style="{ width: '100%', height: '100%' }"
						viewBox="0 0 22.6 26.2"
						fill="currentColor"
					>
						<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
						<path
							class="fill_white"
							d="M6.9,16c0-0.1,0.1-0.3,0.2-0.4c1.9-1.9,3.9-3.9,5.8-5.8c0.4-0.4,0.9-0.3,1,0.2c0.2,0.6,0.2,0.4-0.2,0.9
	c-1.9,1.9-3.8,3.8-5.7,5.7c-0.3,0.3-0.5,0.1-0.8,0C7.1,16.5,7,16.3,6.9,16"
						/>
						<path
							class="fill_white"
							d="M14.5,11.3c0.2,0.4,0.3,0.9,0.5,1.3c0,0.1-0.1,0.2-0.1,0.3c-1.3,1.3-2.6,2.6-3.9,3.9c-0.2,0.2-0.4,0.4-0.6,0.6
	c-0.1,0.1-0.2,0.1-0.3,0.1c-0.4-0.1-0.8-0.3-1.2-0.4C10.6,15.2,12.6,13.3,14.5,11.3"
						/>
						<path
							class="fill_white"
							d="M11,17.9c1.4-1.4,2.9-2.9,4.3-4.3c0.2,0.4,0.3,0.9,0.5,1.3c0,0.1,0,0.2-0.1,0.2c-1,1-2.1,2.1-3.1,3.1
	c-0.1,0.1-0.2,0.1-0.3,0.1C11.8,18.2,11.4,18.1,11,17.9"
						/>
						<path
							class="fill_white"
							d="M16.1,15.9c0.2,0.4,0.3,0.9,0.5,1.3c0,0.1,0,0.2-0.1,0.3c-0.5,0.6-1.1,1.1-1.6,1.6c-0.1,0.1-0.2,0.1-0.2,0.1
	c-0.5-0.1-0.9-0.3-1.3-0.5C14.2,17.8,15.1,16.8,16.1,15.9"
						/>
						<path
							class="fill_white"
							d="M9.7,12c-0.9-0.3-1.8-0.6-2.6-0.9c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2,0-0.2-0.1-0.1-0.3c0.1-0.1,0.1-0.3,0.2-0.5
	c1.2,0.4,2.4,0.8,3.6,1.2C9.8,11.5,9.8,11.7,9.7,12"
						/>
						<path
							class="fill_white"
							d="M9.4,10.1c-0.2,0-0.5,0.1-0.7,0.1c-0.1-0.5-0.1-1-0.2-1.5C8.4,8,8.3,7.4,8.2,6.7c0-0.2,0-0.3,0.2-0.3
	c0.1,0,0.3,0,0.5-0.1C9,7.6,9.2,8.8,9.4,10.1"
						/>
						<path
							class="fill_white"
							d="M15.5,19.5c0.5-0.5,0.9-0.9,1.4-1.4c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.3-0.3,0.7-0.6,0.6
	C16.2,19.8,15.8,19.6,15.5,19.5"
						/>
						<path
							class="fill_white"
							d="M5.5,13.9c0.2-0.4,0.1-0.7-0.2-1c0.4,0.1,0.7-0.1,0.9-0.5c0.1,0.4,0.3,0.6,0.8,0.7c-0.4,0.2-0.6,0.5-0.5,0.9
	C6.2,13.7,5.9,13.7,5.5,13.9"
						/>
						<path
							class="fill_white"
							d="M10.5,9c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.5,0.3,0.5,0.6c0,0.3-0.2,0.5-0.5,0.5C10.7,9.5,10.5,9.3,10.5,9"
						/>
						<path
							class="fill_white"
							d="M6,8.4c-0.3,0-0.6-0.3-0.5-0.6c0-0.3,0.2-0.5,0.5-0.5c0.3,0,0.6,0.2,0.6,0.5C6.5,8.2,6.3,8.4,6,8.4"
						/>
					</svg>
				</div>
				<div
					class="anniversary"
					v-if="showAnniversary(employee.date_employment) > 0 && employee.show_aniversary"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						:style="{ width: '100%', height: '100%' }"
						viewBox="0 0 22.6 26.2"
						fill="currentColor"
					>
						<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
					</svg>
					<div style="position: absolute; top: 0; left: 0; color: #ffffff; width: 100%; text-align: center; font-weight: 700; font-size: 16px; line-height: 31px">{{ showAnniversary(employee.date_employment) }}</div>
				</div>
				<div class="name_container">
					<div>
						<div>
							<div class="name">{{ employee.name}}</div>
							<div class="function">{{ employee.function }}</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="toppanel_container"
				:class="screenIndex <= 4 ? '' : 'hidden'"
				:style="{marginLeft: team2 ? '-447px' : '0px'}"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="0.2,0.2 0.2,29.5 344.4,229.3 688.4,29.5 688.4,0.2 " />
				</svg>
				<div class="team">
					{{ team.name }}
				</div>
			</div>

			<div
				v-if="team2"
				class="toppanel_container"
				:style="{marginLeft: '447px'}"
				:class="screenIndex <= 4 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="0.2,0.2 0.2,29.5 344.4,229.3 688.4,29.5 688.4,0.2 " />
				</svg>
				<div class="team">

					{{ team2.name }}

				</div>
			</div>

			<div
				class="bottompanel_container"
				:style="{marginLeft: team2 ? '-447px' : '0px'}"
				:class="screenIndex >= 9 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="688.2 229.1 688.2 199.8 344 0 0 199.8 0 229.1 688.2 229.1" />
				</svg>
				<div class="team">{{ team.name }}</div>
			</div>

			<div
				v-if="team2"
				class="bottompanel_container"
				:style="{marginLeft: '447px'}"
				:class="screenIndex >= 9 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="688.2 229.1 688.2 199.8 344 0 0 199.8 0 229.1 688.2 229.1" />
				</svg>
				<div class="team">{{ team2.name }}</div>
			</div>

			<div
				class="rightpanel_container"
				:class="screenIndex == 8 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="0.2,0.2 0.2,29.5 344.4,229.3 688.4,29.5 688.4,0.2 " />
				</svg>
				<div class="team">{{ team.name }}</div>
			</div>

			<div
				class="leftpanel_container"
				:class="screenIndex == 5 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style="color: #ffffff"
					viewBox="0 0 688.6 229.5"
					fill="currentColor"
				>
					<polygon points="0.2,0.2 0.2,29.5 344.4,229.3 688.4,29.5 688.4,0.2" />
				</svg>
				<div class="team">{{ team.name }}</div>
			</div>

			<div
				class="hexagon_background hexagon_background_bl"
				:class="screenIndex == 3 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 22.6 26.2"
					fill="currentColor"
				>
					<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
				</svg>
			</div>

			<div
				class="hexagon_background hexagon_background_tl"
				:class="screenIndex == 11 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 22.6 26.2"
					fill="currentColor"
				>
					<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
				</svg>
			</div>

			<div
				class="hexagon_background hexagon_background_br"
				:class="screenIndex == 2 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 22.6 26.2"
					fill="currentColor"
				>
					<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
				</svg>
			</div>

			<div
				class="hexagon_background hexagon_background_tr"
				:class="screenIndex == 10 ? '' : 'hidden'"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 22.6 26.2"
					fill="currentColor"
				>
					<polygon points="0,6.6 0,19.7 11.3,26.2 22.6,19.7 22.6,6.6 11.3,0 " />
				</svg>
			</div>
			<transition name="fade">

				<div
					v-if="showTeamImage && teamImage && teamImage.directus_files_id.id"
					style="height:100%;width:100%;position:absolute;z-index:20;background:#000;background-position: center; background-size:cover;"
					:style="{backgroundImage: 'url(' + directusAsset(teamImage.directus_files_id.id) + ')'}"
				>

				</div>

			</transition>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import _ from "lodash";

export default {
	name: "SchermSmoelenboek",
	components: {},
	data() {
		return {
			showTeamImage: false,
			teamImageIndex: 0,
			teamImage: null,
			repeater: null,
			repeaterFirstTime: true,
			timeoutReloader: null,
			scale: 1,
			canvas: {
				width: 1920,
				height: 1080,
			},
			hexagon: {
				width: 130,
				height: 130 * 1.159375,
				trans_x: 128,
				trans_y: 128 * 1.159375 * 0.75,
			},
			hexagonIndexes: [
				81, 86, 80, 87, 65, 71, 64, 72, 95, 101, 94, 102, 50, 57, 51,
				56, 111, 116, 110, 117, 35, 41, 34, 42, 125, 131, 124, 132,
			],
			hexagonTextPositions: [
				"right",
				"left",
				"left",
				"right",
				"right",
				"left",
				"left",
				"right",
				"right",
				"left",
				"left",
				"right",
				"left",
				"right",
				"right",
				"left",
				"right",
				"left",
				"left",
				"right",
				"right",
				"left",
				"left",
				"right",
				"right",
				"left",
				"left",
				"right",
			],

			employeesTest: [
				{
					id: 1,
					hexagonIndex: 81,
					pos: "right",
					name: "Employee 0",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 1,
				},
				{
					id: 2,
					hexagonIndex: 86,
					pos: "left",
					name: "Employee 1",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 2,
				},
				{
					id: 3,
					hexagonIndex: 80,
					pos: "left",
					name: "Employee 2",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 3,
				},
				{
					id: 4,
					hexagonIndex: 87,
					pos: "right",
					name: "Employee 3",
					function: "Function",
					anniversary: 5,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 4,
				},
				{
					id: 5,
					hexagonIndex: 65,
					pos: "right",
					name: "Employee 4",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 5,
				},
				{
					id: 6,
					hexagonIndex: 71,
					pos: "left",
					name: "Employee 5",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 6,
				},
				{
					id: 7,
					hexagonIndex: 64,
					pos: "left",
					name: "Employee 6",
					function: "Function",
					anniversary: null,
					birthday: this.$dayjs(),
					photoUrl: "https://via.placeholder.com/256x280?text=" + 7,
				},
				{
					id: 8,
					hexagonIndex: 72,
					pos: "right",
					name: "Employee 7",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 8,
				},
				{
					id: 9,
					hexagonIndex: 95,
					pos: "right",
					name: "Employee 8",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 9,
				},
				{
					id: 10,
					hexagonIndex: 101,
					pos: "left",
					name: "Employee 9",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 10,
				},
				{
					id: 11,
					hexagonIndex: 94,
					pos: "left",
					name: "Employee 10",
					function: "Function",
					anniversary: 15,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 11,
				},
				{
					id: 12,
					hexagonIndex: 102,
					pos: "right",
					name: "Employee 11",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 12,
				},
				{
					id: 13,
					hexagonIndex: 50,
					pos: "left",
					name: "Employee 12",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 13,
				},
				{
					id: 14,
					hexagonIndex: 57,
					pos: "right",
					name: "Employee 13",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 14,
				},
				{
					id: 15,
					hexagonIndex: 51,
					pos: "right",
					name: "Employee 14",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 15,
				},
				{
					id: 16,
					hexagonIndex: 56,
					pos: "left",
					name: "Employee 15",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 16,
				},
				{
					id: 17,
					hexagonIndex: 111,
					pos: "right",
					name: "Employee 16",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 17,
				},
				{
					id: 18,
					hexagonIndex: 116,
					pos: "left",
					name: "Employee 17",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 18,
				},
				{
					id: 19,
					hexagonIndex: 110,
					pos: "left",
					name: "Employee 18",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 19,
				},
				{
					id: 20,
					hexagonIndex: 117,
					pos: "right",
					name: "Employee 19",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 20,
				},
				{
					id: 21,
					hexagonIndex: 35,
					pos: "right",
					name: "Employee 20",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 21,
				},
				{
					id: 22,
					hexagonIndex: 41,
					pos: "left",
					name: "Employee 21",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 22,
				},
				{
					id: 23,
					hexagonIndex: 34,
					pos: "left",
					name: "Employee 22",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 23,
				},
				{
					id: 24,
					hexagonIndex: 42,
					pos: "right",
					name: "Employee 23",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 24,
				},
				{
					id: 25,
					hexagonIndex: 125,
					pos: "right",
					name: "Employee 24",
					function: "Function",
					anniversary: 10,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 25,
				},
				{
					id: 26,
					hexagonIndex: 131,
					pos: "left",
					name: "Employee 25",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 26,
				},
				{
					id: 27,
					hexagonIndex: 124,
					pos: "left",
					name: "Employee 26",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 27,
				},
				{
					id: 28,
					hexagonIndex: 132,
					pos: "right",
					name: "Employee 27",
					function: "Function",
					anniversary: null,
					birthday: null,
					photoUrl: "https://via.placeholder.com/256x280?text=" + 28,
				},
			],
		};
	},
	computed: {
		screenIndex() {
			if (this.$route.query.screenIndex == 6) {
				this.$router.push("/scherm-video/");
				return;
			}
			if (this.$route.query.screenIndex == 7) {
				this.$router.push("/scherm-logo/");
				return;
			}
			return this.$route.query.screenIndex;
		},
		layout() {
			let self = this;
			return {
				canvas: self.canvas,
				hexagon: {
					quantity_x: Math.ceil(
						self.canvas.width / self.hexagon.trans_x
					),
					quantity_y: Math.ceil(
						self.canvas.height / self.hexagon.trans_y
					),
					quantity:
						Math.ceil(self.canvas.width / self.hexagon.trans_x) *
						Math.ceil(self.canvas.height / self.hexagon.trans_y),
					totalwidth:
						Math.ceil(self.canvas.width / self.hexagon.trans_x) *
						self.hexagon.trans_x,
					totalheight:
						Math.ceil(self.canvas.height / self.hexagon.trans_y) *
						self.hexagon.trans_y,
					start_x: -1,
					start_y: -54,
					...self.hexagon,
				},
			};
		},
		display() {
			return 1;
		},
		hexagonIndexes1() {
			let self = this;
			let hexagonIndexes1 = [];
			if (self.team2 && self.team2.id) {
				for (var i = 0; i < self.hexagonIndexes.length; i += 2) {
					hexagonIndexes1.push(self.hexagonIndexes[i]);
				}
			} else {
				for (var i = 0; i < self.hexagonIndexes.length; i += 1) {
					hexagonIndexes1.push(self.hexagonIndexes[i]);
				}
			}
			return hexagonIndexes1;
		},
		hexagonTextPositions1() {
			let self = this;
			let hexagonTextPositions1 = [];
			if (self.team2 && self.team2.id) {
				for (var i = 0; i < self.hexagonTextPositions.length; i += 2) {
					hexagonTextPositions1.push(self.hexagonTextPositions[i]);
				}
			} else {
				for (var i = 0; i < self.hexagonTextPositions.length; i += 1) {
					hexagonTextPositions1.push(self.hexagonTextPositions[i]);
				}
			}
			return hexagonTextPositions1;
		},
		hexagonIndexes2() {
			let self = this;
			let hexagonIndexes2 = [];
			if (self.team2 && self.team2.id) {
				for (var i = 1; i < self.hexagonIndexes.length; i += 2) {
					hexagonIndexes2.push(self.hexagonIndexes[i]);
				}
			} else {
				for (var i = 0; i < self.hexagonIndexes.length; i += 1) {
					hexagonIndexes2.push(self.hexagonIndexes[i]);
				}
			}
			return hexagonIndexes2;
		},
		hexagonTextPositions2() {
			let self = this;
			let hexagonTextPositions2 = [];
			if (self.team2 && self.team2.id) {
				for (var i = 1; i < self.hexagonTextPositions.length; i += 2) {
					hexagonTextPositions2.push(self.hexagonTextPositions[i]);
				}
			} else {
				for (var i = 0; i < self.hexagonTextPositions.length; i += 1) {
					hexagonTextPositions2.push(self.hexagonTextPositions[i]);
				}
			}
			return hexagonTextPositions2;
		},
		teams() {
			return this.$store.state.teams;
		},
		teamsOnThisScreen() {
			let self = this;
			return _.filter(this.$store.state.teams, {
				display: self.screenIndex,
			});
		},
		team1() {
			let self = this;
			if (self.teamsOnThisScreen && self.teamsOnThisScreen.length >= 1) {
				return self.teamsOnThisScreen[0];
			}
			return null;
		},
		team2() {
			let self = this;
			if (self.teamsOnThisScreen && self.teamsOnThisScreen.length >= 2) {
				return self.teamsOnThisScreen[1];
			}
			return null;
		},
		team() {
			let self = this;
			let team = null;
			if (self.team1) {
				team = self.team1;
			}
			return team;
		},
		employees1() {
			let self = this;
			let employees = null;
			if (self.$store.state.employees && self.team1 && self.team1.id) {
				employees = _.filter(self.$store.state.employees, {
					team: { id: self.team1.id },
				});
				employees.forEach((employee, index) => {
					employee.hexagonIndex = self.hexagonIndexes1[index];
					employee.pos = self.hexagonTextPositions1[index];
					employee.photoUrl = self.directusAsset(employee.image);
					employee.birthday = self.getBirthDay(employee.date_birth);
				});
			}
			return employees;
		},
		employees2() {
			let self = this;
			let employees = null;
			if (self.$store.state.employees && self.team2 && self.team2.id) {
				employees = _.filter(self.$store.state.employees, {
					team: { id: self.team2.id },
				});
				employees.forEach((employee, index) => {
					employee.hexagonIndex = self.hexagonIndexes2[index];
					employee.pos = self.hexagonTextPositions2[index];
					employee.photoUrl = self.directusAsset(employee.image);
					employee.birthday = self.getBirthDay(employee.date_birth);
				});
			}
			return employees;
		},
		employees() {
			let self = this;
			let employees = null;
			if (self.employees1) {
				employees = self.employees1;
			}
			if (employees && self.employees2) {
				employees = _.concat(employees, self.employees2);
			}
			return employees;
			//return self.employeesTest;
		},
	},
	methods: {
		hexagonPosition(nmbr) {
			let self = this;
			let rowindex =
				(Math.floor((nmbr - 1) / self.layout.hexagon.quantity_x) *
					self.layout.hexagon.quantity_x) /
				self.layout.hexagon.quantity_x;
			let colindex = nmbr - 1 - rowindex * self.layout.hexagon.quantity_x;
			let rowOdd = rowindex % 2 == 1;
			let pos_x =
				self.layout.hexagon.start_x +
				colindex * self.layout.hexagon.trans_x -
				(rowOdd ? self.layout.hexagon.trans_x / 2 : 0);
			let pos_y =
				self.layout.hexagon.start_y +
				rowindex * self.layout.hexagon.trans_y;
			return { x: pos_x, y: pos_y };
		},
		scaleOuterContainer() {
			let container = this.$refs.outer_container;
			const minScale = 0.05;
			const maxScale = 4;
			let scale = Math.min(
				(window.innerWidth + 2) / container.offsetWidth,
				(window.innerHeight + 2) / container.offsetHeight
			);
			scale = Math.min(maxScale, Math.max(minScale, scale));
			//console.log(scale);
			this.scale = scale;
		},
		directusAsset(id) {
			let self = this;
			if (id) {
				return (
					self.$store.state.DIRECTUS_URL_ASSETS +
					id +
					"?access_token=" +
					self.$store.state.DIRECTUS_TOKEN
				);
			} else {
				return "/images/placeholder.jpg";
			}
		},
		getBirthDay(date) {
			let self = this;
			if (date) {
				date =
					self.$dayjs().format("YYYY") +
					"-" +
					self.$dayjs(date).format("MM-DD");
			}
			return date;
		},
		showBirthDay(date) {
			let self = this;
			let diff = null;
			if (date) {
				diff = self.$dayjs().diff(date, "days");
			}
			if ((diff >= 0 && diff <= 2) || (diff < 0 && diff >= 0)) {
				return true;
			}
			return false;
		},
		showAnniversary(date) {
			let self = this;
			let diff = null;
			let dateAnniversary = null;
			let anniversarys = _.reverse([
				5, 10, 15, 20, 25, 30, 35, 40, 45, 50,
			]);
			let returnYears = 0;
			anniversarys.forEach((years) => {
				if (date) {
					dateAnniversary = self.$dayjs(date).add(years, "years");
					diff = self.$dayjs().diff(dateAnniversary, "days");
					if (
						(diff >= 0 && diff <= 10) ||
						(diff < 0 && diff >= -10)
					) {
						//console.log(diff);

						returnYears = years;
					}
				}
			});
			return returnYears;
		},
		showRandomTeamImage() {
			let self = this;
			self.repeater = setTimeout(
				() => {
					if (
						self.team &&
						self.team.images &&
						self.team.images.length > 0
					) {
						if (self.showTeamImage) {
							self.teamImage =
								self.team.images[self.teamImageIndex];
							self.teamImageIndex = self.teamImageIndex + 1;
							if (
								self.teamImageIndex == self.team.images.length
							) {
								self.teamImageIndex = 0;
							}
							self.showTeamImage = false;
						} else {
							self.showTeamImage = true;
						}
					}

					self.showRandomTeamImage();
				},
				self.showTeamImage ? 5000 : self.repeaterFirstTime ? 0 : 30000
			);
		},
	},
	async mounted() {
		let self = this;

		/*
		self.hexagonIndexes.forEach((i, index) => {
			self.employees.push({
				id: index + 1,
				hexagonIndex: i,
				name: "Employee " + index,
				photoUrl: "https://i.pravatar.cc/256",
			});
		});
		*/

		if (!self.$store.state.teams) {
			await self.$store.dispatch("getTeams");
		}

		if (!self.$store.state.employees) {
			await self.$store.dispatch("getEmployees");
		}

		self.scaleOuterContainer();

		self.timeoutReloader = setTimeout(() => {
			window.location.reload();
		}, 1000 * 60 * 60 * 4);
	},
	created() {
		let self = this;

		window.addEventListener("resize", this.scaleOuterContainer);

		let startSeconds = [0, 5, 25, 35, 20, 50, 60, 55, 45, 15, 40, 30, 10];
		let now = new Date();
		//let startSecond = self.screenIndex * 5;
		let startSecond = startSeconds[self.screenIndex];

		let startTime = new Date(
			now.getFullYear(),
			now.getMonth(),
			now.getDate(),
			now.getHours(),
			now.getMinutes(),
			startSecond,
			0
		).getTime();
		let timeout = startTime - now;
		if (timeout < 0) {
			timeout = 60000 + timeout;
		}

		setTimeout(() => {
			self.showRandomTeamImage();
			self.repeaterFirstTime = false;
		}, timeout);
	},
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
